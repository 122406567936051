@import "../../globals.scss";
@import "../../mixins.scss";

.assess-loading {
    margin-top: 50px;
}

.assess-container {
    background-color: $white;
    margin: 40px 20px 30px 20px;

    .assess-body {
        display: flex;
        flex-direction: row;
        gap: 2%;

        .mix-view-container {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 100%;

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 30px;

                .assess-mix-select-container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;

                    .mix-select-text {
                        margin-top: 5px;
                        font-size: 26px;
                    }

                    .status-icon {
                        margin-top: 13px;
                    }

                    .saved-icon {
                        color: $success;
                    }

                    .warn-icon {
                        color: $warn;
                    }
                }
            }

            .results-container {
                display: flex;
                flex-direction: column;
                gap: 30px;

                .result-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .risk-container {
                        display: flex;
                        flex-direction: column;

                        .risk-title {
                            color: $primaryColor;
                            font-size: 60px;
                            font-weight: $bold;
                        }

                        .improvement-label-container {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            align-items: center;

                            .improvement-label {
                                color: $text-input;
                                font-weight: $semi-bold;
                            }

                            .percent-label {
                                color: $primaryColor;
                                font-size: 18px;
                                font-weight: $semi-bold;
                            }
                        }

                        .risk-label-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;

                            .risk-label {
                                color: $text-input;
                                font-weight: $light;
                            }

                            .tooltip-icon {
                                color: $blue;
                            }
                        }
                    }

                    .chart-container {
                        .chart-label {
                            color: $text-input;
                            font-weight: $bold;
                            font-size: 20px;
                        }
                    }

                    @include lg {
                        width: 50%;
                    }
                }

                .constituents-chart-container {
                    @include lg {
                        .constituents-chart-padding {
                            padding-left: 50px;
                        }
                    }
                }

                @include lg {
                    flex-direction: row;
                    gap: 0;
                }
            }

            .table-info-container {
                display: flex;
                flex-direction: column;

                .header-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    flex-direction: column;
                    padding-bottom: 20px;

                    .table-label {
                        color: $text-input;
                        font-weight: $bold;
                        font-size: 20px;
                    }

                    .actions-container {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        .save-button {
                            width: 120px;
                        }

                        .save-as-button {
                            width: 180px;
                        }
                    }

                    @include lg {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }

                .table-container {
                    width: 100%;
                    overflow-x: auto;
                    max-height: 500px;
                    overflow-y: auto;

                    table {
                        .blue-cell {
                            background-color: $light-blue;
                        }

                        .green-cell {
                            background-color: $light-green-2;
                        }

                        .centered-cell {
                            text-align: center;
                        }

                        .right-align-cell {
                            text-align: right;
                        }

                        .bold-cell {
                            font-weight: $bold;
                        }

                        thead {
                            background-color: $off-white;
                            position: sticky;
                            top: 0;
                            z-index: 8;

                            .title-cell {
                                font-weight: $bold;
                            }

                            .tooltip-icon {
                                color: $blue;
                                margin-left: 5px;
                            }

                            .input-cell {
                                width: 120px;
                            }

                            tr {
                                th {
                                    font-weight: $light;
                                    padding: 5px;
                                }
                            }
                        }

                        tbody {
                            background-color: $white;
                            color: $text-input;

                            .remove-icon {
                                color: $grey;

                                &:hover {
                                    cursor: pointer;
                                    color: $alert;
                                }
                            }

                            .selected {
                                background-color: $primaryColor;
                                color: $white;

                                &:hover {
                                    background-color: $primaryColor;
                                }

                                .select-dropdown {
                                    color: $text-input;
                                }

                                .remove-icon {
                                    color: $white;

                                    &:hover {
                                        cursor: pointer;
                                        color: $alert;
                                    }
                                }
                            }

                            .disabled {
                                color: $grey;
                            }

                            .selectable-cell {
                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            tr {
                                height: 50px;

                                td {
                                    font-weight: $light;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }

                .add-constituents-container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    padding-top: 5px;
                    align-items: center;

                    .add-constituents-label {
                        color: $primaryColor;

                        &:hover {
                            text-decoration: underline $primaryColor;
                            cursor: pointer;
                        }
                    }

                    .add-icon {
                        color: $grey;
                    }
                }
            }
        }

        .mix-view-container-compare {
            display: flex;
            flex-direction: column;
            gap: 30px;
            width: 49%;

            .header {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                padding-top: 30px;

                .assess-mix-select-container {
                    display: flex;
                    flex-direction: column;
                    gap: 10px;

                    .mix-select-text {
                        margin-top: 5px;
                        font-size: 26px;
                    }

                    .status-icon {
                        margin-top: 13px;
                    }

                    .saved-icon {
                        color: $success;
                    }

                    .warn-icon {
                        color: $warn;
                    }

                    @include lg {
                        flex-direction: row;
                    }
                }

                .close-icon {
                    color: $grey;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .results-container {
                display: flex;
                flex-direction: column;
                gap: 50px;

                .result-container {
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .risk-container {
                        display: flex;
                        flex-direction: column;

                        .risk-title {
                            color: $primaryColor;
                            font-size: 60px;
                            font-weight: $bold;
                        }

                        .improvement-label-container {
                            display: flex;
                            flex-direction: row;
                            gap: 5px;
                            align-items: center;
                            height: 30px;

                            .improvement-label {
                                color: $text-input;
                                font-weight: $semi-bold;
                            }

                            .percent-label {
                                color: $primaryColor;
                                font-size: 18px;
                                font-weight: $semi-bold;
                            }
                        }

                        .risk-label-container {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            gap: 5px;

                            .risk-label {
                                color: $text-input;
                                font-weight: $light;
                            }

                            .tooltip-icon {
                                color: $blue;
                            }
                        }
                    }

                    .chart-container {
                        .chart-label {
                            color: $text-input;
                            font-weight: $bold;
                            font-size: 20px;
                        }
                    }
                }

                .constituents-chart-container {
                    display: none;

                    @include lg {
                        display: block;
                    }
                }
            }

            .table-info-container {
                display: flex;
                flex-direction: column;

                .header-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 10px;
                    flex-direction: column;
                    padding-bottom: 20px;

                    .table-label {
                        font-weight: $bold;
                        color: $text-input;
                        font-size: 20px;
                    }

                    .actions-container {
                        display: flex;
                        flex-direction: row;
                        gap: 10px;

                        .save-button {
                            width: 120px;
                        }

                        .save-as-button {
                            width: 180px;
                        }
                    }

                    @include lg {
                        flex-direction: row;
                        justify-content: space-between;
                    }
                }

                .table-container {
                    width: 100%;
                    overflow-x: auto;
                    height: 250px;
                    overflow-y: auto;
                    white-space: nowrap;

                    table {
                        .blue-cell {
                            background-color: $light-blue;
                        }

                        .green-cell {
                            background-color: $light-green-2;
                        }

                        .centered-cell {
                            text-align: center;
                        }

                        .right-align-cell {
                            text-align: right;
                        }

                        .bold-cell {
                            font-weight: $bold;
                        }

                        thead {
                            background-color: $off-white;
                            position: sticky;
                            top: 0;
                            z-index: 8;

                            .title-cell {
                                font-weight: $bold;
                            }

                            .tooltip-icon {
                                color: $blue;
                                margin-left: 5px;
                            }

                            .input-cell {
                                width: 120px;
                            }

                            tr {
                                th {
                                    font-weight: $light;
                                    padding: 5px;
                                }
                            }
                        }

                        tbody {
                            background-color: $white;
                            color: $text-input;

                            .remove-icon {
                                color: $grey;

                                &:hover {
                                    cursor: pointer;
                                    color: $alert;
                                }
                            }

                            .selected {
                                background-color: $primaryColor;
                                color: $white;

                                &:hover {
                                    background-color: $primaryColor;
                                }

                                .select-dropdown {
                                    color: $text-input;
                                }

                                .remove-icon {
                                    color: $white;

                                    &:hover {
                                        cursor: pointer;
                                        color: $alert;
                                    }
                                }
                            }

                            .disabled {
                                color: $grey;
                            }

                            .selectable-cell {
                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            tr {
                                height: 50px;

                                td {
                                    font-weight: $light;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }

                .add-constituents-container {
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    padding-top: 5px;
                    align-items: center;

                    .add-constituents-label {
                        color: $primaryColor;

                        &:hover {
                            text-decoration: underline $primaryColor;
                            cursor: pointer;
                        }
                    }

                    .add-icon {
                        color: $grey;
                    }
                }
            }

            .footer {
                display: flex;
                flex-direction: row;
                gap: 20px;

                .save-button {
                    width: 120px;
                }

                .save-as-button {
                    width: 180px;
                }
            }
        }
    }

    @include lg {
        margin: 40px 80px 30px 80px;
    }
}
