@import "../../globals.scss";

.erp-switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 20px;
    margin: 0;

    .erp-switch--input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .erp-switch-slider {
            background-color: $light-green;
        }

        &:checked + .erp-switch-slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
            background-color: $green;
        }

        &:focus + .erp-switch-slider {
            box-shadow: 0 0 1px #2196f3;
        }
    }

    .erp-switch-slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $grey;
        -webkit-transition: 0.3s;
        transition: 0.3s;
        border-radius: 34px;
        height: 19px;
        width: 50px;

        &::before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 0px;
            bottom: -4px;
            background-color: $dark-grey;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            border-radius: 50%;
        }
    }
}
