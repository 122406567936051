@import "../../globals.scss";
@import "../../mixins.scss";

.mix-select-container {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .mix-select-toggle-dropdown {
        border-bottom: 3px solid $primaryColor;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 5px;

        white-space: nowrap;
        width: fit-content;

        &:hover {
            cursor: pointer;
        }

        .selected-option {
            color: $text-input;
            font-weight: $semi-bold;
            font-size: 30px;
            max-width: 60vw;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .open-arrow {
            color: $primaryColor;
            margin-top: 3px;
            margin-left: 10px;
        }
    }

    .mix-select-toggle-add {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .add-icon {
            color: $grey;

            &:hover {
                cursor: pointer;
                color: $light-green;
            }
        }
    }

    .mix-select-toggle-compare {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .mix-compare-button {
            width: 300px;
            height: 40px;
        }
    }

    .mix-select-menu {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: auto;
        border: 1px solid $grey;
        background-color: $white;
        border-radius: 5px;
        width: 300px;
        margin-top: 2px;
        z-index: 9;
        color: $text-primary;

        .mix-select-new-button {
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding: 10px;
            justify-content: center;
            align-items: center;
            color: $text-input;
            border-bottom: 1px solid $grey;

            &:hover {
                cursor: pointer;
                background-color: $light-grey;
            }
        }

        .mix-select-item {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            text-align: left;
            word-wrap: normal;
            padding: 10px 10px 10px 10px;

            &:hover {
                cursor: pointer;
                background-color: $primaryColor;
                color: $white;
            }

            .item-actions {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .action-icon {
                    &:hover {
                        cursor: pointer;
                        color: $grey;
                    }
                }
            }
        }

        .mix-selected-item {
            background-color: $primaryColor;
            color: $white;
        }
    }

    .mix-select-label {
        font-size: 12px;
        color: $primaryColor;
    }
}
