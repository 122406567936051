@import "../../globals.scss";
@import "../../mixins.scss";

.explore-loading {
    margin-top: 50px;
}

.explore-container {
    background-color: $white;
    margin: 40px 20px 30px 20px;

    .filter {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        padding: 30px 0;
        gap: 20px;

        .filter-text {
            font-size: 30px;
        }
    }

    .explore-body {
        display: flex;
        flex-direction: column;
        gap: 40px;

        .explore-section {
            display: flex;
            flex-direction: column;
            gap: 20px;

            width: 100%;

            .how-to-panel {
                display: flex;
                flex-direction: row;
                gap: 10px;
                border: 3px dashed $blue;
                border-radius: 5px;
                background-color: $light-blue;
                padding: 10px;

                .info-icon {
                    color: $blue;
                    margin: auto;
                }

                .info-text {
                    color: $text-input;
                    font-weight: $regular;
                    font-size: 13px;
                }
            }

            .chart-options-panel {
                display: flex;
                flex-direction: row;
                gap: 60px;

                background-color: $off-white;
                padding: 10px;
                padding-left: 30px;
                align-items: center;

                overflow: auto;

                .label {
                    font-size: 18px;
                    font-weight: $semi-bold;
                }

                .toggle {
                    display: flex;
                    flex-direction: column;

                    .toggle-buttons {
                        display: flex;
                        flex-direction: row;

                        .toggle-button {
                            border-radius: 0;
                        }
                    }
                }
            }

            .label {
                color: $text-input;
                font-weight: $bold;
                font-size: 20px;
            }

            .constituents-table-container {
                width: 100%;

                display: flex;
                flex-direction: column;
                gap: 20px;

                .constituents-table {
                    max-height: 750px;
                    overflow: auto;

                    table {
                        font-size: 0.9rem;

                        .blue-cell {
                            background-color: $light-blue;
                        }

                        .centered-cell {
                            text-align: center;
                        }

                        .right-align-cell {
                            text-align: right;
                        }

                        thead {
                            background-color: $off-white;
                            position: sticky;
                            top: 0;
                            z-index: 99;

                            .title-cell {
                                font-weight: $bold;
                            }

                            .tooltip-icon {
                                color: $blue;
                                margin-left: 5px;
                            }

                            .input-cell {
                                width: 120px;
                            }

                            tr {
                                th {
                                    font-weight: $light;
                                    padding: 5px;
                                }
                            }
                        }

                        tbody {
                            background-color: $white;

                            .selected {
                                background-color: $primaryColor;
                                color: $white;

                                &:hover {
                                    background-color: $primaryColor;
                                }

                                .add-icon {
                                    color: $white;

                                    &:hover {
                                        color: $light-green;
                                    }
                                }
                            }

                            .selectable-cell {
                                &:hover {
                                    cursor: pointer;
                                }
                            }

                            tr {
                                height: 50px;

                                td {
                                    font-weight: $light;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
            }

            .product-list-container {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .products-table {
                    max-height: 300px;
                    overflow: auto;

                    border: 1px solid $light-grey;
                    border-radius: 3px;

                    table {
                        width: 100%;

                        thead {
                            background-color: $off-white;
                            position: sticky;
                            top: 0;

                            tr {
                                th {
                                    padding: 10px;
                                }
                            }
                        }

                        tbody {
                            .list-cell {
                                display: flex;
                                flex-direction: column;
                                gap: 10px;
                            }

                            .action-cell {
                                display: flex;
                                flex-direction: row;
                                gap: 20px;
                            }

                            tr {
                                border-bottom: 1px solid $light-grey;

                                td {
                                    padding: 10px;
                                    color: $text-input;
                                    font-weight: $semi-bold;
                                }
                            }
                        }
                    }
                }
            }

            .constituents-chart-container {
                display: flex;
                flex-direction: column;
                gap: 30px;
                width: 100%;

                @include lg {
                    width: 50%;

                    .constituents-chart-padding {
                        padding-left: 50px;
                    }
                }
            }

            .constituent-info {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .constituent-name-label {
                    color: $text-input;
                    font-size: 26px;
                }

                .info-line {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;

                    .info-value {
                        color: $text-input;
                        font-weight: $bold;
                    }
                }

                .title {
                    font-size: 20px;
                    font-weight: $semi-bold;
                }

                .constituent-info-box {
                    background-color: $off-white;
                    padding: 10px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }

                .active-ingredient-container {
                    display: flex;
                    flex-direction: column;
                    gap: 15px;

                    .active-ingredient-info {
                        display: grid;
                        gap: 10px;
                    }
                }
            }

            @include lg {
                width: calc(50% - 20px);
            }
        }

        @include lg {
            flex-direction: row;
        }
    }

    @include lg {
        margin: 40px 80px 30px 80px;
    }
}
