@import "../../globals.scss";
@import "../../mixins.scss";

.register {
    color: $white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    .register-form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        margin: auto;
        padding: 10px;

        .titles {
            text-align: center;
            margin-bottom: 15px;
            color: $primaryColor;

            h1 {
                font-size: 3.5rem;
                font-weight: $bold;
            }

            h1,
            h2 {
                margin-bottom: 20px;
            }
        }

        form {
            width: 100%;

            .form--actions {
                width: 100%;

                .auth-error {
                    text-align: center;
                    margin-bottom: 10px;
                }

                button {
                    width: 100%;
                    font-weight: $bold;
                    padding: 10px;
                }

                .links {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    margin-top: 10px;
                    padding: 0 10px;

                    a {
                        color: $white;
                        font-weight: $semi-bold;
                        text-decoration: underline;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }
            }
        }
    }

    @include lg {
        .register-form {
            width: 50%;

            form {
                width: 75%;

                .form--actions {
                    .links {
                        flex-direction: row;
                        justify-content: space-around;
                    }
                }
            }
        }
    }
}
