@import "../../globals.scss";
@import "../../mixins.scss";

.pt-select-container {
    display: flex;
    flex-direction: column;
    gap: 3px;

    .pt-select {
        border-bottom: 3px solid $primaryColor;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 5px;

        white-space: nowrap;
        width: fit-content;

        &:hover {
            cursor: pointer;
        }

        .selected-option {
            color: $text-input;
            font-weight: $bold;
            font-size: 30px;
            max-width: 60vw;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .open-arrow {
            color: $primaryColor;
            margin-top: 3px;
            margin-left: 10px;
        }
    }

    .pt-select-label {
        font-size: 12px;
        color: $primaryColor;
    }

    .pt-select-menu {
        display: flex;
        flex-direction: column;
        max-height: 300px;
        overflow: auto;
        border: 1px solid $grey;
        background-color: $white;
        border-radius: 5px;
        width: 250px;
        margin-top: 2px;
        z-index: 100;

        .pt-select-item {
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            word-wrap: normal;
            padding: 10px 0 10px 0;

            &:hover {
                cursor: pointer;
                background-color: $primaryColor;
                color: $white;
            }
        }

        .pt-selected-item {
            background-color: $primaryColor;
            color: $white;
        }

        .pt-select-search-box {
            margin: 5px;
            width: auto;
        }
    }
}
