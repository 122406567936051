@import "../../globals.scss";
@import "../../mixins.scss";

.manage-account-container {
    background-color: $white;
    height: 100%;
    margin: 0 30px;

    .manage-account--tabs {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        padding: 20px 0;

        .manage-account--tab {
            flex: 1;
            margin-bottom: 10px;

            button {
                width: 100%;
            }
        }

        @include lg {
            flex-direction: row;

            .manage-account--tab {
                margin-bottom: 0;

                &:first-child {
                    margin-left: 0;
                    margin-right: 10px;
                }

                &:last-child {
                    margin-right: 0;
                    margin-left: 10px;
                }
            }
        }
    }

    .change-password {
        display: flex;
        flex-direction: column;
        margin-top: 20px;

        .change-password--inputs {
            display: flex;
            flex-direction: column;

            div {
                flex: 1;
                margin-bottom: 10px;
            }
        }

        .change-password--action {
            display: flex;
            justify-content: flex-start;

            button {
                width: 100%;

                svg {
                    margin-left: 5px;
                }
            }
        }

        @include lg {
            .change-password--inputs {
                flex-direction: row;

                div {
                    &:first-child {
                        margin-left: 0;
                        margin-right: 10px;
                    }

                    &:last-child {
                        margin-right: 0;
                        margin-left: 10px;
                    }
                }
            }

            .change-password--action {
                justify-content: flex-end;

                button {
                    width: auto;
                }
            }
        }
    }

    .manage-users {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;

        .group-users {
            display: flex;
            flex-direction: row;
            flex: 1;
            flex-wrap: wrap;
            align-items: flex-start;

            .group-user {
                display: flex;
                align-items: center;
                margin-right: 15px;

                svg {
                    margin-right: 5px;
                }

                &.group-admin {
                    svg {
                        color: $primaryColor;
                    }
                }

                .group-user-remove {
                    color: $text-primary !important;
                    margin-left: 5px;
                    cursor: pointer;

                    &:hover {
                        color: $alert !important;
                    }
                }
            }
        }

        .group-users-input {
            width: 100%;
            margin-bottom: 10px;

            .group-users-input--container {
                display: flex;

                .email-input {
                    width: 100%;

                    .erp-input-container {
                        border-top-right-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                    }
                }

                button {
                    background-color: $primaryColor;
                    border-color: $primaryColor;
                    border-top-left-radius: 0;
                }

                .menu-toggle {
                    border-bottom-left-radius: 0;

                    &:after {
                        content: none !important;
                    }
                }

                .menu-actions {
                    padding: 0;

                    button {
                        background-color: $white;
                        color: $primaryColor;
                        padding: 0.5rem 1rem;

                        &:hover {
                            background-color: $light-grey;
                            color: $dark-grey;
                        }
                    }
                }
            }
        }

        @include lg {
            flex-direction: row;

            .group-users-input {
                width: 400px;
                margin-bottom: 0;
            }
        }
    }
}
