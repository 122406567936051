@import "../../globals.scss";
@import "../../mixins.scss";

.pt-header {
    display: flex;
    flex-direction: row;
    align-items: center;

    min-height: 75px;
    padding: 0 40px;
    justify-content: space-between;
    border-bottom: 1px solid $light-grey;

    .header-icon-container {
        display: flex;
        flex-direction: row;
        align-items: center;

        .header-icon {
            height: 100%;
            display: none;

            img {
                height: 100%;
                width: 300px;
            }

            @include lg {
                display: initial;
                position: relative;
                left: -10px;
            }
        }

        .beta-logo {
            display: none;
            background: $warn;
            color: $white;
            font-size: 24px;
            width: 80px;
            text-align: center;
            border-radius: 5px;

            @include lg {
                display: initial;
            }
        }

        .header-content {
            height: 100%;
            display: flex;
            padding-left: 20px;

            .header-label {
                display: flex;
                align-items: center;
                font-size: 20px;
                font-weight: $regular;

                svg {
                    margin-right: 20px;
                }

                .system-icon {
                    color: $primaryColor;
                }

                .model-icon {
                    color: $secondaryColor;
                }
            }

            @include lg {
                &.explorer-header-content {
                    justify-content: flex-end;
                }
            }

            .links {
                height: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0 30px;

                .link {
                    display: flex;
                    height: 100%;
                    justify-content: center;
                    align-items: center;
                    max-width: 170px;
                    width: auto;
                    position: relative;
                    cursor: pointer;
                    text-decoration: none;
                    color: $text-primary;
                    margin: 0 20px;
                    transition: color 0.15s ease-in-out;

                    span {
                        display: none;
                        font-weight: $semi-bold;
                        font-size: 18px;
                    }

                    .link-indicator {
                        width: 100%;
                        height: 5px;
                        position: absolute;
                        bottom: 0;
                        background-color: $primaryColor;
                    }

                    &:hover {
                        color: $primaryColor;
                        font-weight: $semi-bold;
                    }

                    @include sm {
                        svg {
                            margin-right: 20px;
                        }

                        span {
                            display: initial;
                        }
                    }
                }
            }
        }
    }

    .header-menu-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 30px;

        .issue-button {
            min-height: 40px;
        }

        .user-menu {
            .user-menu-dropdown-toggle {
                background-color: unset !important;
                border: unset !important;
                border-radius: unset !important;
                color: $text-primary !important;
                font-weight: $bold !important;
                outline: none !important;

                &::after {
                    display: none;
                }
            }

            .user-menu-dropdown-menu {
                max-width: 300px;
                min-width: 250px !important;
                z-index: 1001 !important;

                a,
                .group {
                    cursor: pointer;
                    padding: 5px;
                    color: $text-primary;
                    margin-left: 0;
                    font-size: 0.9rem;
                    font-weight: $semi-bold;

                    &:hover {
                        color: $text-primary;
                        text-decoration: none;
                        background-color: $light-grey;
                    }
                }

                .name {
                    margin: 8px;
                    color: $text-primary;
                }

                .title {
                    padding: 8px;
                    color: $grey;
                    font-weight: $bold;

                    h6 {
                        margin: 0;
                    }
                }

                .groups {
                    max-height: 200px;
                    height: auto;
                    overflow-y: auto;
                    overflow-x: hidden;

                    .group {
                        svg {
                            margin-left: 10px;
                        }

                        &.selected {
                            background-color: $primaryColor;
                            color: $white;
                        }
                    }
                }

                @include sm {
                    .groups {
                        max-height: 300px;
                    }
                }
            }
        }
    }

    @include lg {
        padding: 0 80px;
    }
}
