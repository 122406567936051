@import "../../globals.scss";
@import "../../mixins.scss";

.navigation-tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .navigation-tab {
        display: flex;
        justify-content: center;
        align-items: center;

        min-height: 50px;
        width: 100%;
        border-radius: 3px;
        padding: 5px;

        background-color: $light-grey;

        &:hover {
            cursor: pointer;
            background-color: $grey;

            .navigation-tab-label {
                color: $white;
            }
        }

        .navigation-tab-label {
            font-weight: $bold;
            font-size: 1.5rem;
            color: $text-primary;
            text-align: center;
        }
    }

    .selected {
        background-color: $primaryColor;

        &:hover {
            cursor: pointer;
            background-color: $primaryColor;
        }

        .navigation-tab-label {
            color: $white;
        }
    }

    @include lg {
        flex-direction: row;

        .navigation-tab {
            width: 50%;
        }
    }
}
