@import "src/globals.scss";
@import "src/mixins.scss";

html,
body {
    color: $text-primary;
    background-color: $white;
    font-family: $font-stack;
    font-weight: $regular;
    margin: 0;
    padding: 0;
    height: 100%;
    overflow: hidden;
}

#root {
    height: 100%;
}

.epr-toast-container {
    width: 100% !important;
    min-width: 320px;

    @include sm {
        width: auto !important;
    }

    button {
        svg {
            color: $white;
        }
    }
}

.react-contexify {
    .react-contexify__item__content {
        color: $text-primary;
    }

    .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
    .react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
        background-color: $primaryColor;
        color: $white;
    }
}
