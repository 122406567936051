@import "../../globals.scss";

.constituents-chart {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .label {
        color: $text-input;
        font-weight: $bold;
        font-size: 20px;
    }

    .chart-container {
        display: grid;
        justify-content: left;

        .chart {
            grid-row-start: 1;
            grid-column-start: 2;

            #chart-log {
                background-image: linear-gradient(34deg, #2c3077, #0276bb, #09c0de, #fccd0a, #e24a99, #824c9e, #38184b);
            }

            #chart-linear {
                background-image: linear-gradient(34deg, #824c9e, #38184b);
            }
        }

        .y-labels {
            grid-row-start: 1;
            grid-column-start: 1;

            display: flex;
            flex-direction: column;
            justify-content: space-between;

            max-width: 90px;

            .title {
                font-weight: $bold;
                rotate: -90deg;
                text-align: center;
                align-self: flex-end;
                white-space: nowrap;
            }

            .axis-label {
                font-weight: $light;
                text-align: left;
            }
        }

        .x-labels {
            grid-row-start: 2;
            grid-column-start: 2;
            grid-column-end: 3;

            display: flex;
            flex-direction: row;
            justify-content: space-between;

            padding-top: 5px;

            .title {
                font-weight: $bold;
                padding-top: 10px;
                text-align: center;
            }

            .axis-label {
                font-weight: $light;
            }
        }

        .gradient-bar-container {
            grid-row-start: 3;
            grid-column-start: 2;

            padding-top: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;

            .title {
                font-weight: $semi-bold;
            }

            .gradient-bar-log {
                height: 40px;

                background-image: linear-gradient(
                    to right,
                    #2c3077 0%,
                    #0276bb 10%,
                    #09c0de 20%,
                    #fccd0a 35%,
                    #e24a99 55%,
                    #824c9e 75%,
                    #38184b 100%
                );
            }

            .gradient-bar-linear {
                height: 40px;

                background-image: linear-gradient(to right, #824c9e, #38184b);
            }

            .constituent-marker {
                position: relative;
                height: 40px;
                width: 5px;
                border-radius: 3px;
                background-color: $off-white;
            }

            .gradient-bar-label {
                position: relative;
            }
        }
    }
}
